.float{
    &_request{

        &-statement{
            margin: 2em 0;
            .box{
                // width: 336px;
                // height: 76px;
                background: #FFFFFF;
                border: 1px solid #D0D5DD;
                border-radius: 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px 10px;
                p{
                    margin: 0 35px 0 10px;
                }
            }
            .accountNumber{
                margin-left: 2em;
                h4{
                    margin: 0;
                }
            }
            .view{
                cursor: pointer;
                width: 40px;
            }
        }

        &-director{
            .column{
                padding: 2em 0;
                border-top: 1px solid #DFDBDB;
                border-bottom: 1px solid #DFDBDB;

                th{
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #667085;
                    padding-right: 2em;
                }
                td{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #344054;
                    padding-right: 2em;
                }
            }
        }

        &-action{
            float:right;
            padding: 2em;
            .decline{
                width: 185px;
                height: 48px;
                background: #D92D20;
                border: 1px solid #D92D20;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                border-radius: 8px;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: #FFFFFF;
                margin-right: 1em;
            }
            .next{
                padding: 0 1rem;
                height: 48px;
                background: #0046E6;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                border-radius: 8px;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: #FFFFFF;
                border: none;
                margin-right: 10px;
            }
        }

    }

    &_offer{
        width: 100%;
        box-sizing: border-box;

        .amount{
            border-left: 4.5px solid #D0D7E6;
            padding-left: 12px;
            margin-bottom: 2em;
        }

        .duration{
            border-left: 4.5px solid #D0D7E6;
            padding-left: 12px;
        }

        &-loan{
            padding: 41.5px 24px;
            // width: 703px;
            background: #FFFFFF;
            border-radius: 8px;
            box-shadow: 0px 13.3657px 44.5523px rgba(160, 163, 189, 0.5);

            h5{
                font-weight: 500;
                font-size: 21px;
                line-height: 27px;
                color: #000000;
                margin-bottom: 1em;
            }


            .form{
                &-input{
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 1em;

                    label{
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        color: #344054;
                        margin-bottom: 10px;
                    }
                    input{
                        background: #FFFFFF;
                        border: 1px solid #D0D5DD;
                        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                        border-radius: 4px;
                        width: 100%;
                        height: 38px;
                        padding: 0 10px;
                    }

                    &-select{
                        margin: -20px 0 20px;
                    }
                }

                &-breakdown{
                    margin: 40px 0 15px;

                    .column{
                        padding: 2em 0;
                        border-top: 1px solid #DFDBDB;
                        border-bottom: 1px solid #DFDBDB;
        
                        th{
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                            color: #667085;
                            padding-right: 2em;
                        }
                        td{
                            font-weight: 400;
                            font-size: 14.22px;
                            line-height: 19px;
                            color: #06152B;
                        }
                    }
                }

               
            }

            button{
                // height: 48px;
                background: #0046E6;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                border-radius: 8px;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: #FFFFFF;
                padding: 8px 20px;
                border: none
            }
        }

        

    }

    &_modal{
        padding: 20px;
        box-sizing: border-box;

        &-options {
            margin: 15px 0 0;
            h5 {
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 25px;
                margin: -15px 0 20px;
                @media (max-width: 560px) {
                    font-size: 20px;
                    line-height: 28px;
                }
            }

            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 50px;
                @media (max-width: 560px) {
                    font-size: 12px;
                    line-height: 20px;
                }
            }

            .radio{
                [type="radio"]:checked,
                [type="radio"]:not(:checked) {
                    position: absolute;
                    left: -9999px;
                }
                [type="radio"]:checked + label,
                [type="radio"]:not(:checked) + label
                {
                    position: relative;
                    padding-left: 28px;
                    cursor: pointer;
                    line-height: 20px;
                    display: inline-block;
                    color: #344054;;
                    font-weight: 600;
                }
                [type="radio"]:checked + label:before,
                [type="radio"]:not(:checked) + label:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 18px;
                    height: 18px;
                    border: 1px solid $color-sidebar;
                    border-radius: 100%;
                    background: #fff;
                }
                [type="radio"]:checked + label:after,
                [type="radio"]:not(:checked) + label:after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    background: $color-sidebar;
                    position: absolute;
                    top: 2.7px;
                    left: 3px;
                    border-radius: 100%;
                    -webkit-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                }
                [type="radio"]:not(:checked) + label:after {
                    opacity: 0;
                    -webkit-transform: scale(0);
                    transform: scale(0);
                }
                [type="radio"]:checked + label:after {
                    opacity: 1;
                    -webkit-transform: scale(1);
                    transform: scale(1);
                }
            }
        }

        &-action{
            padding: 7px;
            text-align: center;
            color: white;
            background: $color-sidebar;
            border: 1px solid $color-sidebar;
            border-radius: 6px;
            width: 100%;
        }

        &-terms{
            margin: 40px 20px;
            ::-webkit-scrollbar {
                width: 10px;
            }
            ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px white; 
            border-radius: 10px;
            }
            ::-webkit-scrollbar-thumb {
                background: #EEEFF0; 
                border-radius: 10px;
            }
            ::-webkit-scrollbar-thumb:hover {
                background: #EEEFF0; 
            }
        }
    }
}